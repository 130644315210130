<template>
  <v-card>
    <v-list two-line subheader>
      <v-subheader>{{ title }}</v-subheader>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Type</v-list-item-title>
          <v-list-item-subtitle
            class="wrap-text-list"
            v-html="peer.type"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Address</v-list-item-title>
          <v-list-item-subtitle
            class="wrap-text-list"
            v-html="peer.address ? peer.address : 'not determined'"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Port</v-list-item-title>
          <v-list-item-subtitle
            class="wrap-text-list"
            v-html="peer.port"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Protocol</v-list-item-title>
          <v-list-item-subtitle
            class="wrap-text-list"
            v-html="peer.protocol"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Candidate general info</v-list-item-title>
          <v-list-item-subtitle
            class="wrap-text-list"
            v-html="peer.candidate"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "one-peer-info",

  props: {
    title: {
      type: String,
      default: "",
    },
    peer: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style lang="scss">
.wrap-text-list {
  white-space: normal !important;
}
</style>
