<template>
  <div class="arht-chat">
    <v-card
      v-if="messagesList.length > 0"
      color="grey darken-3"
      class="white--text scroll-color-grey-darken-1 pa-2 mb-2"
      max-height="226"
      id="scroll-elm"
    >
      <div
        class="v-timeline-item theme--dark"
        :class="classObject(msg)"
        v-for="(msg, i) in messagesList"
        :key="i"
      >
        <div class="wrapper-msg">
          <div class="v-timeline-item__body">
            <div class="msg-label">{{ msg.descrStr }}</div>
            <div class="elevation-2 v-card v-sheet theme--dark rounded-card">
              <div class="v-card__text">{{ msg.message }}</div>
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <v-form ref="form" v-model="form">
      <v-layout row wrap class="pt-6">
        <v-flex sm10 xs10>
          <v-textarea
            v-model="message"
            auto-grow
            outlined
            color="grey"
            label="Message"
            rows="1"
            hide-details
            class="ml-4"
          ></v-textarea>
        </v-flex>
        <v-flex sm2 xs2>
          <v-btn
            :disabled="!form || btnSendLoading"
            :loading="btnSendLoading"
            class="white--text btn-send-msg"
            color="grey darken-1"
            depressed
            @click="sendMsg()"
            block
            text
          >
            <v-icon dark class="pl-1">send</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import GeneralMixin from "@/mixins/general.js"
import WsConnMixin from "@/mixins/websocket-connection.js"
import moment from "moment"
import UserService from "@/common/api/user.service"

export default {
  name: "chat",

  props: {
    room: {
      type: String,
      default: null,
    },
  },

  mixins: [GeneralMixin, WsConnMixin],

  data() {
    return {
      btnSendLoading: true,
      message: "",
      messagesList: [],
      users: [],

      agreement: false,
      dialog: false,
      email: undefined,
      form: false,
      password: undefined,
      phone: undefined,
      rules: {
        email: (v) => (v || "").match(/@/) || "Please enter a valid email",
        length: (len) => (v) =>
          (v || "").length >= len ||
          `Invalid character length, required ${len}`,
        password: (v) =>
          (v || "").match(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
          ) ||
          "Password must contain an upper case letter, a numeric character, and a special character",
        required: (v) => !!v || "This field is required",
      },
    }
  },

  watch: {
    isAuthenticatedWs: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.initChat()
        }
      },
    },
  },

  created() {
    this.initWebsocketConnection()
  },

  mounted: function () {
    this.getUsersList()
  },

  destroyed: function () {
    let self = this
    self.websocket_common_conn.send(
      JSON.stringify({
        path: "1.0.chat.room.exit",
        args: {
          room_name: self.room,
        },
      })
    )
  },

  methods: {
    initWebsocketConnection() {
      let self = this
      self.websocket_common_conn.onmessage = function (value) {
        if (Object.keys(value).length > 0) {
          let data = JSON.parse(value.data)
          let path = _.get(data, "path", "")
          if (path == "1.0.chat.room.enter") {
            self.requestMessagesList()
          } else if (path == "1.0.chat.room.messages") {
            let dMsgs = _.get(data, ["data", "messages"], null)
            if (dMsgs) {
              dMsgs = _.filter(dMsgs, (o) => o.cmd == "message")
              self.parsedMsgs(dMsgs)
            }
            setTimeout(function () {
              var objDiv = document.getElementById("scroll-elm")
              if (objDiv) objDiv.scrollTop = objDiv.scrollHeight
            }, 200)
          } else if (path == "1.0.chat.room.message.send") {
            self.requestMessagesList()
          } else if (path == "1.0.auth.login") {
            self.initChat()
          }
        }
      }

      if (self.isAuthenticatedWs) {
        self.initChat()
      }
    },

    initChat() {
      let self = this
      self.btnSendLoading = false
      self.websocket_common_conn.send(
        JSON.stringify({
          path: "1.0.chat.room.enter",
          args: {
            room_name: self.room,
          },
        })
      )
    },

    classObject: function (item) {
      let self = this
      let isCurrentUserMsg =
        self.getCurrentUser && item.user_key == self.getCurrentUser._key
      return {
        me: isCurrentUserMsg,
        other: !isCurrentUserMsg,
      }
    },

    sendMsg() {
      let self = this
      if (self.message != "") {
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.chat.room.message.send",
            args: {
              message: self.message,
              room_name: self.room,
            },
          })
        )
      }
      self.message = ""
    },

    getUsersList: function () {
      let self = this
      return new Promise((resolve) => {
        // @todo limit 500, users from different companies will not see eachother. user from hash will not see any users
        UserService.usersList({ params: { p: 1, pp: 500 } }).then(
          (response) => {
            self.users = response.data.data.users
            resolve()
          }
        )
      })
    },

    parsedMsgs(dMsgs) {
      let self = this
      let msgs = []
      self.getUsersList().then(() => {
        _.forEach(dMsgs, (o) => {
          let descrStr = ""
          if (o.user_key !== self.getCurrentUser._key) {
            let user = _.find(self.users, (u) => {
              return u._key === o.user_key
            })
            let fullName = self.getFullname(user)
            descrStr = fullName + " | "
          }
          let time = moment.unix(o.timestamp).format("MMM DD, YYYY HH:mm")
          descrStr = descrStr + time
          o.descrStr = descrStr
          msgs.push(o)
        })
        self.messagesList = msgs
      })
    },

    requestMessagesList() {
      let self = this
      self.websocket_common_conn.send(
        JSON.stringify({
          path: "1.0.chat.room.messages",
          args: {
            room_name: self.room,
          },
        })
      )
    },
  },

  computed: {
    ...mapGetters({
      websocket_common_conn: "getWebsocketCommonConnection",
      getCurrentUser: "currentUser",
      isAuthenticatedWs: "isAuthenticatedWs",
    }),
  },
}
</script>

<style lang="sass">
#scroll-elm
  overflow-x: hidden
  overflow-y: auto

.arht-chat .v-timeline-item .msg-label
  color: #BDBDBD
  font-size: 13px
  padding: 0 4px

  .arht-chat .v-timeline-item.me .msg-label
    text-align: right

  .btn-send-msg
    height: 57px
    min-height: 57px
    margin: 0
    margin-right: 6px

  .arht-chat .v-card__text
    padding: 12px

  .arht-chat .v-timeline-item
    padding-bottom: 5px
    padding-top: 5px

  .arht-chat .v-timeline-item.other
    flex-direction: row

  .arht-chat .v-timeline-item.me
    flex-direction: row-reverse

  .arht-chat .v-timeline-item .wrapper-msg
    max-width: calc(80% - 48px)

  .arht-chat .v-timeline-item .v-timeline-item__body
    max-width: max-content

  .arht-chat .v-timeline-item .v-card:before,
  .arht-chat .v-timeline-item .v-card:after
    content: none !important

  .rounded-card
    border-radius: 8px

  .arht-chat .v-timeline-item.me .v-timeline-item__body .v-card
    background-color: #455A64

  .arht-chat .v-timeline-item.other .v-timeline-item__body .v-card
    background-color: #616161

  .chat-container-msgs
    max-height: 400px
    overflow-y: scroll

  .custom-loader
    animation: loader 1s infinite
    display: flex

  @-moz-keyframes loader
    from
      transform: rotate(0)
    to
      transform: rotate(360deg)

  @-webkit-keyframes loader
    from
      transform: rotate(0)
    to
      transform: rotate(360deg)

  @-o-keyframes loader
    from
      transform: rotate(0)
    to
      transform: rotate(360deg)

  @keyframes loader
    from
      transform: rotate(0)

    to
      transform: rotate(360deg)
</style>
