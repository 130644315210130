import { mapGetters } from "vuex"
import _ from "lodash"

export default {
  data() {
    return {}
  },
  methods: {
    closeDialog() {
      let self = this
      let route =
        this.$route.name === "engines_speed_test_hash"
          ? "/login/"
          : "/engines/speed-test/"
      self.$router
        .push(route)
        .then(() => {
          self.$store.commit("saveTestInProgress", false)
          self.$store.dispatch("speedTestPartnerDisconnectedAction", false)
          self.$store.dispatch("speedTestShowCompletedDialogAction", false)
          setTimeout(function () {
            self.$validator.errors.clear("form-engines")
          }, 20)
          self.$store.dispatch("speedTestResetData").then(() => {
            self.$store.dispatch("saveLoaderAction", false)
            self.$store.dispatch("isSavingSpeedTest", false)
          })
        })
        .catch(() => {
          // catch push errors here
        })
    },

    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("speedTestGetSingleAction", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },

    getTurnServersList: function () {
      let self = this
      let getParams = {
        params: {
          type: "turn_server",
        },
      }

      self.$store
        .dispatch("turnServersCompanyGetLists", getParams)
        .then(function (response) {
          let turnServers = response.data.data.settings_multi || []
          let localTurns = _.unionBy(
            turnServers,
            self.modelWebengine.listLocalTurns,
            "_key"
          )
          self.$store.dispatch("saveListLocalTurns", localTurns)
          let remoteTurns = _.unionBy(
            turnServers,
            self.modelWebengine.listRemoteTurns,
            "_key"
          )
          self.$store.dispatch("saveListRemoteTurns", remoteTurns)
        })
    },

    getSignalServersList: function () {
      let self = this
      let getParams = {
        params: {
          type: "signal_server",
        },
      }
      self.$store.dispatch("signalServersList", getParams)
    },
  },

  computed: {
    ...mapGetters({
      modelWebengine: "getModelWebengine",
    }),
  },
}
