<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="white--text">
          <v-layout>
            <v-flex xs5>
              <v-card-title primary-title>
                <div>
                  <div class="headline" :data-testid="`test-title-${item.id}`">
                    {{ item.label }}
                  </div>
                </div>
              </v-card-title>
            </v-flex>
            <v-flex xs7>
              <v-card-text>
                <div class="text-xs-right">
                  <v-progress-circular
                    :rotate="360"
                    :size="45"
                    :width="6"
                    :value="progressingValue"
                    color="teal"
                    v-if="
                      item.status === 'in-progress' ||
                      (testInProgress && item.status === 'stopped')
                    "
                    :data-testid="`test-progress-${item.id}`"
                  >
                    {{ progressingValue }}%
                  </v-progress-circular>
                  <!-- <v-chip v-if="item.status == 'finished'" color="green" text-color="white">finished
                  </v-chip> -->
                </div>
              </v-card-text>
            </v-flex>
          </v-layout>

          <v-card-text>
            <div
              v-if="
                ['in-progress', 'finished', 'stopped'].includes(item.status)
              "
            >
              <!-- :statsData="item.stats" -->
              <webrtc-statistics
                v-if="item.remote.peerId"
                :connId="item.remote.peerId"
                :duration="testMiliSecondsDuration"
                :statsData="item.stats"
                :identifier="item.id"
                :type="item.type"
                :watchZeroResult="true"
                @statistic-finished="statisticFinished"
                @progress-duration="progressDurationTest"
                @statistic-stopped-prematurely="statisticStoppedPrematurely"
                ref="webrtcStats"
              ></webrtc-statistics>

              <v-btn
                class="ma-2"
                outlined
                color="indigo"
                @click="viewConnectivityInfo(item)"
                :data-testid="`btn-connectivity-info-${item.id}`"
              >
                Connectivity info
              </v-btn>
            </div>
            <div
              v-else-if="item.status == 'awaiting'"
              :data-testid="`queued-${item.id}`"
            >
              Queued ...
            </div>
            <div v-if="errorStatisticZero">
              <v-alert
                text
                outlined
                dense
                type="error"
                border="left"
                dismissible
                icon="mdi-alert-octagon-outline"
                class="mt-3"
                :data-testid="`notification-no-direct-conn-${item.id}`"
              >
                Direct connection can not be established. Trying selected TURN
                server.
              </v-alert>
            </div>
            <div v-if="errorPeerConnBroken">
              <v-alert
                text
                outlined
                dense
                type="error"
                border="left"
                dismissible
                icon="mdi-alert-octagon-outline"
                class="mt-3"
                :data-testid="`notification-webrtc-failed-${item.id}`"
              >
                Webrtc connection failed.
              </v-alert>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <peers-info-dialog :dialog.sync="dialogConnectivity" :task="item" />
  </div>
</template>

<script>
import _ from "lodash"
import WebrtcStatistics from "@/components/webrtc/WebrtcStatistics"
import { mapGetters } from "vuex"
import publicIp from "public-ip"
import iplocation from "iplocation"
import PeersInfoDialog from "./PeersInfoDialog"

export default {
  name: "speed-test-result",

  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      },
    },
    remoteConnId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      progressDuration: 0,
      errorStatisticZero: false,
      errorPeerConnBroken: false,
      dialogConnectivity: false,
    }
  },

  components: {
    WebrtcStatistics,
    PeersInfoDialog,
  },

  mounted: function () {
    this.$root.$on("statistic-zero-finish", (inProgressWorkerTask) => {
      if (inProgressWorkerTask?.id === this.item?.id) {
        this.errorStatisticZero = true
      }
    })
    this.$root.$on("peer-conn-broken", (inProgressWorkerTask) => {
      if (inProgressWorkerTask && inProgressWorkerTask?.id === this.item?.id) {
        this.errorPeerConnBroken = true
      }
    })
  },

  watch: {
    "item.status": {
      handler(val) {
        if (val === "stopped" && this.$refs.webrtcStats) {
          this.$refs.webrtcStats.stopStatisticPrematurely()
        }
      },
      deep: true,
    },
  },

  methods: {
    statisticFinished(stats) {
      this.handleStatistic(stats)
    },
    statisticStoppedPrematurely(stats) {
      this.handleStatistic(stats, "stopped")
    },
    handleStatistic(stats, status = "finished") {
      let self = this
      publicIp.v4().then((ip) => {
        let locationInfo = null
        iplocation(ip)
          .then((lInfo) => {
            locationInfo = lInfo
          })
          .catch((err) => {})
          .then(() => {
            let rawStats = {
              ip: ip,
              locationInfo: locationInfo,
              data: stats.generalChartData,
              task_settings: self.item,
            }
            self.$store
              .dispatch("speedTestSaveRawStatisticsAction", rawStats)
              .then(function (response) {
                let findedTaskKey = _.findKey(self.workerTasks, {
                  id: self.item.id,
                })
                let workerTasksChanged = self.workerTasks
                workerTasksChanged[findedTaskKey].status = status
                self.$store
                  .dispatch(
                    "speedTestSaveWorkerTasksAction",
                    workerTasksChanged
                  )
                  .then(function () {
                    if (status === "finished")
                      self.$emit("statistic-finished", rawStats)
                  })
              })
          })
      })
    },
    progressDurationTest(val) {
      this.progressDuration = val
    },
    getChartsImages() {
      return this.$refs.webrtcStats.getChartsImages()
    },
    viewConnectivityInfo(item) {
      this.dialogConnectivity = true
    },
  },

  computed: {
    ...mapGetters({
      activeSpeedTest: "getActiveSpeedTest",
      workerTasks: "getWorkerTasks",
      testMiliSecondsDuration: "getSpeedTestMiliSecondsDuration",
      isTestInitiator: "getIsTestInitiator",
      peers: "getWebrtcPeers",
      testInProgress: "getTestInProgres",
    }),

    progressingValue() {
      return parseInt(
        (this.progressDuration / this.testMiliSecondsDuration) * 100
      )
    },
  },
}
</script>
