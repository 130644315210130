<template>
  <v-dialog
    :value="dialog"
    persistent
    max-width="800px"
    dark
    content-class="dialog-peer-info"
  >
    <v-card>
      <v-card-title>
        <span class="headline"> Peers info for {{ task.label }}</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 v-if="localPeer || remotePeer">
              <v-row>
                <v-col cols="6" class="px-0">
                  <one-peer-info
                    title="Local Receiver Peer Info"
                    :peer="localPeer"
                  ></one-peer-info>
                </v-col>

                <v-col cols="6" class="px-0">
                  <one-peer-info
                    title="Remote Sender Peer Info"
                    :peer="remotePeer"
                  ></one-peer-info>
                </v-col>
              </v-row>
            </v-flex>
            <v-flex xs12 sm12 v-else> There are no peer info </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="$emit('update:dialog', false)"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import OnePeerInfo from "./OnePeerInfo"

export default {
  name: "peers-info-dialog",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    task: {
      type: [Object],
      default: null,
    },
  },

  components: {
    OnePeerInfo,
  },

  computed: {
    ...mapGetters({}),
    localPeer() {
      return this.task?.local?.peerData
    },
    remotePeer() {
      return this.task?.remote?.peerData
    },
  },
}
</script>
