import WebrtcSender from "../webrtc-sender"

export default class WebrtcSenderSpeedTest extends WebrtcSender {
  getCallerName() {
    return "WebrtcSenderSpeedTest"
  }

  getVideoEncoder() {
    return "h264"
  }
}
