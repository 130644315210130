import WebrtcReceiver from "../webrtc-receiver"

export default class WebrtcReceiverSpeedTest extends WebrtcReceiver {
  getCallerName() {
    return "WebrtcReceiverSpeedTest"
  }

  getVideoEncoder() {
    return "h264"
  }
}
