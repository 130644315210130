<template>
  <v-dialog v-model="dialog" persistent fullscreen key="dialog-speed-test">
    <v-card v-if="errorText" data-testid="container-errors-run-test">
      <v-card-text class="pt-6">
        <v-alert type="error" data-testid="notification-errors-run-test">
          {{ errorText }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          plain
          :color="Pallette.actionButtons.delete"
          :to="'/engines/speed-test'"
          data-testid="btn-cancel-run-test"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="!errorText" data-testid="container-run-test">
      <FirefoxUseAlert wrapper-class="mx-5 mt-2" alert-class="mb-0" />

      <v-card-title>
        <span
          class="headline"
          v-html="getTitle()"
          data-testid="title-run-test"
        ></span>
      </v-card-title>
      <v-card-text>
        <div>
          <speed-test-status-dialogs
            @runTestFromDialog="runTestFromDialog"
            @restartTest="restartTest"
            @saveUnfinishedStats="saveUnfinishedStats"
            @saveCompletedStats="saveCompletedStats"
            @viewResultsAsPdf="viewResultsAsPdf"
            @closeDialogLocal="closeDialogLocal"
            @bandwithChanged="bandwithChanged"
            ref="statusDialogs"
            v-if="showStatusDialogs"
            :editedIndex="editedIndex"
            :saveRestart="saveRestart"
          />

          <v-layout wrap row>
            <!-- v-if="!activeSpeedTest.finished" -->
            <v-flex class="pa-4" sm6 xs12 data-testid="container-sending">
              <v-toolbar dark>
                <v-toolbar-title data-testid="sending-title">
                  Sending
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-card fill-height height="85%">
                <v-container fill-height>
                  <v-layout align-content-center justify-center row>
                    <v-flex md12 sm12 xs12>
                      <video
                        controls
                        crossOrigin="anonymous"
                        id="local_video"
                        muted
                        playsinline
                        loop
                        :src="videoSrc"
                        data-testid="sending-video"
                      />
                      <!-- https://dl.dropboxusercontent.com/s/ekedg1cd8mskyop/arhttestvideo.mp4 -->
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </v-flex>
            <!-- v-if="!activeSpeedTest.finished" -->
            <v-flex class="pa-4" sm6 xs12 data-testid="container-receiving">
              <v-toolbar dark>
                <v-toolbar-title data-testid="title-receiving">
                  Receiving
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card fill-height height="85%">
                <v-container fill-height>
                  <v-layout align-content-center justify-center row>
                    <v-flex
                      md12
                      sm12
                      v-show="!isPreviewRemoteStreamStarted"
                      xs12
                    >
                      <v-card
                        color="black"
                        height="100%"
                        id="remote_video_no_stream"
                        data-testid="notification-receiving-no-stream"
                      >
                        <v-card-text class="text-center title">
                          No video stream
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-flex md12 sm12 v-show="isPreviewRemoteStreamStarted" xs12>
                    <v-alert
                      dense
                      text
                      type="warning"
                      v-if="!playStateReceiving"
                      data-testid="notification-receiving-run-manually"
                    >
                      Video cannot start, please try to run manually.
                    </v-alert>
                    <div id="received_video_container"></div>
                  </v-flex>
                </v-container>
              </v-card>
            </v-flex>
          </v-layout>
          <!--  -->
          <!-- || activeSpeedTest.finished -->
          <template v-if="testInProgress">
            <v-flex
              class="pa-4"
              sm12
              xs12
              data-testid="container-test-in-progress"
            >
              <v-toolbar dark>
                <v-toolbar-title>Test results</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card>
                <v-container fluid grid-list-lg>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <!-- v-if="item" -->
                      <TestResult
                        :item="item"
                        @statistic-finished="statisticFinished"
                        v-for="item in workerTasks"
                        :key="'tRes' + item.id"
                        :ref="'tRes' + item.id"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </v-flex>
          </template>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="error"
          text
          @click.native="closeDialogLocal()"
          data-testid="btn-close"
        >
          Close
        </v-btn>

        <!-- <v-btn
          color="indigo" dark text
          v-if="activeSpeedTest.finished"
          @click="savePdf()" class="ml-1"
        >
          Export PDF
          <v-icon dark right>picture_as_pdf</v-icon>
        </v-btn> -->

        <v-btn
          id="btn-stop-test"
          dark
          color="orange darken-3"
          class="ml-1"
          text
          v-if="testInProgress"
          @click="stopTest(true)"
          data-testid="btn-stop-test"
        >
          Stop test
          <v-icon dark class="pl-1">mdi-stop</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import TestResult from "./TestResult"
import jsSHA from "jssha"
import _ from "lodash"
import GeneralService from "@/common/general.service"
import {
  percentPacketLost,
  decimalsPercentPacketLost,
} from "@/common/webrtc/helpers"
import * as uuidv1 from "uuid/v1"
import publicIp from "public-ip"
import iplocation from "iplocation"
import SpeedTestStatusDialogs from "./SpeedTestStatusDialogs"
import STCommonMixin from "@/mixins/speed-test/common"
import STWebsocketMixin from "@/mixins/speed-test/ws-conn"
import WsConnMixin from "@/mixins/websocket-connection.js"
import WebrtcSenderSpeedTest from "@/common/webrtc/speed-test/webrtc-sender-speed-test"
import WebrtcReceiverSpeedTest from "@/common/webrtc/speed-test/webrtc-receiver-speed-test"
import FirefoxUseAlert from "@/components/engines/FirefoxUseAlert"

export default {
  name: "run-test-block",
  components: {
    SpeedTestStatusDialogs,
    TestResult,
    FirefoxUseAlert,
  },

  mixins: [STWebsocketMixin, WsConnMixin, STCommonMixin],

  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
    link_hash: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      worker: null,
      stream: null,
      secondIsInformed: false,
      videoSrc: `${window.location.origin}/static/arhttestvideo.mp4`,
      onInitExistWebrtcPartenerSender: false,
      partnerSignalId: null,
      partnerWsConnId: null,
      showDialogRoomBusy: null,
      dClientAllowed: false,
      dialog: true,
      showStatusDialogs: false,
      playerPlaying: false,
      sender: null,
      receiver: null,
      saveRestart: false,
      uniqueId: null,
      statisticZero: {
        me: false,
        partner: false,
      },
      beginedTaskId: null,
      playStateReceiving: true,
    }
  },

  watch: {
    testInProgress: function (newVal, oldVal) {
      let self = this
      self.sender.getOrCreateConnection().then((lConn) => {
        self.receiver.getOrCreateConnection().then(function (rConn) {
          if (!oldVal && newVal && self.isTestInitiator) {
            self.$store.dispatch("speedTestPartnerDisconnectedAction", false)
            self.onInitExistWebrtcPartenerSender =
              self.existWebrtcPartenerSender
            self
              .getPartnerSenderId()
              .then((partnerPeerId) => {
                self.partnerSignalId = partnerPeerId
                self.initializeTestChain()
              })
              .catch(() => {
                // Catch error getPartnerSenderId
              })
          }
        })
      })
    },

    showRunTestDialog: {
      handler(val, oldVal) {
        let self = this
        if (val && val !== oldVal) {
          self.sendIpInfoPartner()
        }
      },
      deep: true,
    },

    roomCliens: {
      handler(val, oldVal) {
        let self = this
        if (self.sender) {
          if (val.length !== oldVal.length) {
            self.sender.getOrCreateConnection().then((lConn) => {
              self.sender.getAspxStreamsList()
            })
          }

          // if (val.length === 2 && oldVal.length !== 2 && self.showPartnerDisconnectedDialog && self.isCurrentClientAllowed) {
          //   self.startWaitingPartner();
          //   self.onInitExistWebrtcPartenerSender = self.existWebrtcPartenerSender;
          // }

          // in case when we detect that our partner has closed test window, in case of 2 points
          self.getItemData().then(() => {
            // in case when first start on page reset old data from sync_data
            // reset sync data on start page
            if (!val.some((r) => self.stSyncDataClients.includes(r))) {
              self.$store.dispatch("speedTestEditAction", {
                slug: self.activeSpeedTest?._key,
                data: {
                  duration: parseInt(self.activeSpeedTest.duration),
                  bandwidth: parseInt(self.activeSpeedTest.bandwidth),
                  sync_data: { type: "unknown", clients: [] },
                },
              })
            }

            // in case when partner is out
            if (self.isPartnerOut) {
              self.onPartnerCloseTestPage()
            }
          })
        }
      },
      deep: true,
    },
  },

  beforeDestroy() {
    if (this.errorText) {
      return
    }
    this.sender.disconnect().then(() => {
      delete this.sender
      this.sender = null
    })
    this.receiver.disconnect().then(() => {
      delete this.receiver
      this.receiver = null
    })
  },

  created() {
    let self = this
    if (self.errorText) {
      return
    }
    self.uniqueId = uuidv1()
    self.$root.$on("initializeTest", () => {
      self.$store.dispatch("saveInitializeSpeedTestAction", {
        testInProgress: true,
        isTestInitiator: true,
      })
    })
  },

  mounted() {
    let self = this
    if (self.errorText) {
      return
    }
    self.getTurnServersList()
    self.getSignalServersList()

    // If not from link hash page then we update data from API
    let pVideoCodec = self.$store.dispatch("saveVideoCodec", "vp8")
    //let pResetSpeedTest = self.link_hash ? Promise.resolve() : self.$store.dispatch('speedTestResetData');
    let pGetData = self.link_hash ? Promise.resolve() : self.getItemData()

    Promise.all([pVideoCodec, pGetData]).then(() => {
      let webrtcCommonProps = {
        signalUrl: self.activeSpeedTest.signal_server_url,
        room: self.activeSpeedTest._key,
        bandwidth: self.activeSpeedTest.bandwidth,
        maxBandwidth: self.activeSpeedTest.bandwidth,
        refComponent: self,
      }
      // initiate WebRTC sender
      let senderProps = {
        ...webrtcCommonProps,
        ...{
          name: self.localPeerNickname,
        },
      }
      self.sender = new WebrtcSenderSpeedTest(senderProps)
      self.sender.on("unknown-received-msg", self.handleUnknownReceivedMsgs)
      self.sender.on("peer-connect-error", () => {
        self.$store.dispatch(
          "speedTestErrors",
          "Speed test could not be started - peer connection cannot be started"
        )
      })
      // initiate WebRTC receiver
      let receiverProps = {
        ...webrtcCommonProps,
        ...{ name: self.remotePeerNickname, reconectionPreview: false },
      }
      self.receiver = new WebrtcReceiverSpeedTest(receiverProps)
      self.receiver.on("state-disconnected", self.handlePeerConnBroken)
      self.receiver.on("state-failed", self.handlePeerConnBroken)
      self.receiver.on("candidate-pair-info", (peerData) => {
        if (this.inProgressWorkerTask) {
          self.$store.dispatch("speedTestSavePeerActiveCandidate", {
            id: this.inProgressWorkerTask.id,
            peerData,
          })
        }
      })
      self.receiver.on("peer-connect-error", () => {
        self.$store.dispatch(
          "speedTestErrors",
          "Speed test could not be started - peer connection cannot be started"
        )
      })
      // self.sender.on('state-disconnected', self.handlePeerConnBroken);
      // self.sender.on('state-failed', self.handlePeerConnBroken);
      self.receiver.on("start-play-state", (state) => {
        self.playStateReceiving = state
      })

      self.sender.getOrCreateConnection().then((lConn) => {
        self.receiver.getOrCreateConnection().then(function (rConn) {
          self.sender.getAspxStreamsList()

          // notificate another room clients to refresh self members data
          _.forEach(self.roomMembersSignal.senders, function (val) {
            let msg_send = {
              from: "", //self.connection.id
              to: val.id,
              type: "refresh-members",
            }
            lConn.emit("message", JSON.stringify(msg_send))
          })

          if (self.activeSpeedTest.finishedLocal) {
            // self.parsedChartDataFinishedTest();
            self.$store
              .dispatch(
                "speedTestSaveWorkerTasksAction",
                self.activeSpeedTest.stats.calculated
              )
              .then(function () {
                // do something
              })
          } else {
            self.$store.dispatch("speedTestResetStatsAction")
          }
          // self.$store.dispatch('speedTestResetStatsAction');
          self.showStatusDialogs = true
        })
      })
    })

    self.$root.$on("statistic-zero", () => {
      self.statisticZero.me = true
      self.sendMsgSocketPartener("statistic-zero-partner").then(() => {
        self.checkFinishStatisticZero()
      })
    })
  },

  methods: {
    getTitle() {
      if (this.testInProgress) {
        return `<span class="blue-grey--text text--lighten-4">Test in progress</span> ${this.activeSpeedTest.name}`
      }
      // if (this.activeSpeedTest.finished) {
      //   return `<span class="blue-grey--text text--lighten-4">Test result for</span> ${this.activeSpeedTest.name}`;
      // }
      return `<span class="blue-grey--text text--lighten-4">You can Run test</span> ${this.activeSpeedTest.name}`
    },

    closeDialogLocal() {
      this.sendMsgSocketPartener("close-dialog")
      this.closeDialog()
    },

    sendIpInfoPartner() {
      let self = this
      let clientInfo = {
        ip: null,
        location: null,
      }
      publicIp
        .v4({
          fallbackUrls: ["https://ifconfig.co/ip"],
        })
        .catch((err) => {
          console.error("Cannot detect IP")
          console.log(err)
        })
        .then((ip) => {
          console.log("detected IP")
          console.log(ip)
          clientInfo.ip = ip
          try {
            self.sender.getOrCreateConnection().then((lConn) => {
              if (self.receiver) {
                self.receiver.getOrCreateConnection().then(function (rConn) {
                  setTimeout(() => {
                    self.onInitExistWebrtcPartenerSender =
                      self.existWebrtcPartenerSender
                    self
                      .getPartnerSenderId()
                      .then((partnerPeerId) => {
                        self.partnerSignalId = partnerPeerId
                        iplocation(ip)
                          .then((locationInfo) => {
                            // console.log('locationInfo')
                            // console.log(locationInfo)
                            clientInfo.location = locationInfo
                            self.sendMsgSocketPartener(
                              "partner-ip-info",
                              clientInfo
                            )
                          })
                          .catch((err) => {
                            console.log("locationInfo ERR")
                            console.log(err)
                            let errorLocationInfo = {
                              city: "Undefined",
                              country: "Undefined",
                              countryCode: "Undefined",
                              ip: "Undefined",
                              latitude: null,
                              longitude: null,
                              postal: "Undefined",
                              region: "Undefined",
                              regionCode: "Undefined",
                              timezone: "Undefined",
                            }
                            clientInfo.location = errorLocationInfo
                            self.sendMsgSocketPartener(
                              "partner-ip-info",
                              clientInfo
                            )
                          })
                      })
                      .catch(() => {
                        // Error on getPartnerSenderId
                      })
                  }, 300)
                })
              } else {
                console.log("self.receiver is null in on sendIpInfoPartner")
              }
            })
          } catch (err) {
            console.log("Error on sendIpInfoPartner")
          }
        })
    },

    postResultData() {
      let self = this
      let postData = {
        slug: self.editedIndex,
        data: {
          stats: self.activeSpeedTest.stats,
          source: self.activeSpeedTest.source,
          destination:
            self.activeSpeedTest.destination !== ""
              ? self.activeSpeedTest.destination
              : "Test with servers",
        },
      }
      return self.$store.dispatch("speedTestPostTestResultAction", postData)
    },

    // generate data for charts when test in finished
    parsedChartDataFinishedTest: function () {
      let self = this
      let statsData = []
      let interval = 2 // interval of writing statistics (now it calculate statistics one in 2 seconds)
      let groupedStats = _.groupBy(self.activeSpeedTest.stats.raw, (o) =>
        o.task_settings.type == "ptop"
          ? o.task_settings.type
          : o.task_settings.type + o.task_settings.turnData._key
      ) //+ o.task_settings.local.peerId
      _.forEach(groupedStats, function (val, key) {
        let task = val[0].task_settings
        task.stats = {
          ip: val[0].ip,
          locationInfo: val[0].locationInfo,
          chartData: {
            dReceived: [],
            pLost: [],
          },
          tableData: {
            videoBytesReceived: 0,
            audioBytesReceived: 0,
            videoPacketsLost: 0,
            audioPacketsLost: 0,
            videoPacketsReceived: 0,
            audioPacketsReceived: 0,
          },
          imgCharts: {
            dReceived: null,
            pLost: null,
          },
        }
        let time = 0

        let titles = ["Time"]
        var i
        //let zeroValues = [0];
        for (i = 0; i < val.length; i++) {
          //zeroValues[i + 1] = 0;
          titles[i + 1] = val[i].ip
          _.set(task, ["stats", "clients", i], {
            ip: val[i].ip,
            locationInfo: val[i].locationInfo,
          })
        }
        task.stats.chartData.dReceived.push(titles)
        task.stats.chartData.pLost.push(titles)

        _.forEach(val[0].data, function (val2, key2) {
          // if (!key2) {
          //     task.stats.chartData.dReceived.push(zeroValues);
          //     task.stats.chartData.pLost.push(zeroValues);
          // }
          var i
          let progress = parseInt(val2.progress) / 1000
          let oneDReceived = [progress]
          let onePLost = [progress]
          for (i = 0; i < val.length; i++) {
            var d = _.get(val, [i, "data", key2]) || {
              videoBytesReceived: 0,
              audioBytesReceived: 0,
              videoPacketsLost: 0,
              audioPacketsLost: 0,
              videoPacketsReceived: 0,
              audioPacketsReceived: 0,
            }
            try {
              oneDReceived[i + 1] =
                (d.videoBytesReceived + d.audioBytesReceived) / interval
              onePLost[i + 1] = d.videoPacketsLost + d.audioPacketsLost
              task.stats.tableData.videoBytesReceived =
                task.stats.tableData.videoBytesReceived + d.videoBytesReceived
              task.stats.tableData.audioBytesReceived =
                task.stats.tableData.audioBytesReceived + d.audioBytesReceived
              task.stats.tableData.videoPacketsLost =
                task.stats.tableData.videoPacketsLost + d.videoPacketsLost
              task.stats.tableData.audioPacketsLost =
                task.stats.tableData.audioPacketsLost + d.audioPacketsLost
              task.stats.tableData.videoPacketsReceived =
                task.stats.tableData.videoPacketsReceived +
                d.videoPacketsReceived
              task.stats.tableData.audioPacketsReceived =
                task.stats.tableData.audioPacketsReceived +
                d.audioPacketsReceived
            } catch (err) {
              console.log("err save stats", err)
            }
          }
          task.stats.tableData.totalBytesReceived =
            task.stats.tableData.videoBytesReceived +
            task.stats.tableData.audioBytesReceived
          task.stats.tableData.totalPacketLost =
            task.stats.tableData.videoPacketsLost +
            task.stats.tableData.audioPacketsLost
          task.stats.tableData.textVideoBytesReceived =
            GeneralService.BytesToMBits(
              task.stats.tableData.videoBytesReceived,
              2
            )
          task.stats.tableData.textAudioBytesReceived =
            GeneralService.BytesToMBits(
              task.stats.tableData.audioBytesReceived,
              2
            )
          task.stats.tableData.textTotalBytesReceived =
            GeneralService.BytesToMBits(
              task.stats.tableData.totalBytesReceived,
              2
            )
          task.stats.chartData.dReceived.push(oneDReceived)
          task.stats.chartData.pLost.push(onePLost)
        })

        task.stats.tableData.videoPercentPacketLost = percentPacketLost(
          task.stats.tableData.videoPacketsReceived,
          task.stats.tableData.videoPacketsLost
        )
        task.stats.tableData.audioPercentPacketLost = percentPacketLost(
          task.stats.tableData.audioPacketsReceived,
          task.stats.tableData.audioPacketsLost
        )
        let totalPL =
          parseFloat(task.stats.tableData.videoPercentPacketLost) +
          parseFloat(task.stats.tableData.audioPercentPacketLost)
        task.stats.tableData.totalPercentPacketLost =
          decimalsPercentPacketLost(totalPL)

        let refff = _.get(self.$refs, `[${"tRes" + task.id}][0]`)
        if (refff) {
          task.stats.imgCharts = refff.getChartsImages()
        }
        statsData.push(task)
      })
      return self.$store.dispatch("speedTestSaveWorkerTasksAction", statsData)
    },

    handleUnknownReceivedMsgs(msg) {
      let self = this
      switch (msg.type) {
        case "worker-tasks-list":
          // Save sync data
          self.getItemData().then(() => {
            if (self.stSyncDataClients.length < 2) {
              let stSyncDataClients = self.stSyncDataClients
              let newSyncClients = [...stSyncDataClients, ...[self.clientWsId]]
              let newSyncData = {
                ...self.speedTestSyncData,
                ...{ clients: newSyncClients },
              }
              self.$store.dispatch("speedTestEditAction", {
                slug: self.activeSpeedTest?._key,
                data: {
                  duration: parseInt(self.activeSpeedTest.duration),
                  bandwidth: parseInt(self.activeSpeedTest.bandwidth),
                  sync_data: newSyncData,
                },
              })
            }

            // write tasks list
            let pms = self.showTestCompletedDialog
              ? [self.startWaitingPartner()]
              : []
            Promise.all(pms).then(() => {
              self.partnerWsConnId = msg.params.wsId
              setTimeout(() => {
                self.partnerSignalId = msg.from
                self.onInitExistWebrtcPartenerSender = true
                self.$store
                  .dispatch(
                    "speedTestSaveWorkerTasksAction",
                    msg.params.workerTasks
                  )
                  .then(() => {
                    self.$store.dispatch("saveInitializeSpeedTestAction", {
                      testInProgress: true,
                    })
                  })
              }, 50)
            })
          })
          break
        case "worker-run-task":
          if (self.$refs.statusDialogs) {
            self.$refs.statusDialogs.stopTimer()
          }
          setTimeout(() => {
            var item = _.find(self.workerTasks, { id: msg.params.taskId })
            if (item) {
              //self.$store.dispatch('speedTestPartnerDisconnectedAction', false)
              self.startStats(item)

              if (msg.params.resend) {
                self.sendMsgSocketPartener("worker-run-task", {
                  taskId: item.id,
                  resend: false,
                })
              }
            } else {
              console.error("cannot find task to run")
            }
          }, 200)
          break
        case "worker-local-peers-initialized":
          var item = _.find(self.workerTasks, { id: msg.params.taskId })
          //self.partnerWsConnId = msg.params.wsId
          if (item) {
            self.startStatsAfterPeersInitialised(item, msg.params.localPeerId)
          } else {
            console.error("cannot find task to run")
          }
          break
        case "single-statistic-finished":
          // save partner statistics and run next test
          self.$store
            .dispatch("speedTestSaveRawStatisticsAction", msg.params.dbStats)
            .then(function () {
              self.$store.dispatch(
                "speedTestSaveDestinationAction",
                msg.params.dbStats.ip
              )
              //setTimeout(function () {
              if (
                self.countStatsForTask(msg.params.dbStats.task_settings.id) >= 2
              ) {
                self.runTest()
              }
              //}, 1000)
            })
          break
        case "statistic-saved":
          self.$store.dispatch("speedTestShowCompletedDialogAction", false)
          self.$store.dispatch("saveInitializeSpeedTestAction", {
            testInProgress: false,
          })
          self.closeDialog()
          break
        case "stop-test":
          self.stopTest()
          break
        case "stop-restart-test":
          self.stopTestOnRestart()
          break
        case "close-dialog":
          self.onPartnerCloseTestPage()
          self.closeDialog()
          break
        case "partner-ip-info":
          self.$store.dispatch("speedTestSavePartnerInfoAction", msg.params)
          break
        case "statistic-zero-partner":
          self.statisticZero.partner = true
          self.checkFinishStatisticZero()
          break
        case "is-saving-speed-test":
          self.$store.dispatch("isSavingSpeedTest", msg.params)
          break
      }
    },

    // what to do when partner test page or entire window was closed
    onPartnerCloseTestPage() {
      if (this.isPartnerOut) {
        this.stopAllPeers(false)
        this.$store.dispatch("speedTestStopAllTasksAction")
        this.$store.dispatch("speedTestPartnerDisconnectedAction", true)
      }
    },

    initializeTestChain() {
      let self = this
      self.$store
        .dispatch("speedTestSaveWorkerTasksAction", self.getWorkTasks())
        .then(function () {
          setTimeout(function () {
            self.sendMsgSocketPartener("worker-tasks-list", {
              workerTasks: self.workerTasks,
              wsId: self.clientWsId,
            })
            self.runTest()
          }, 500)
        })
    },

    statisticFinished(dbStats) {
      let self = this
      console.log("dbStats", dbStats)
      self.$store.dispatch("speedTestSaveSourceAction", dbStats.ip)
      if (self.existWebrtcPartenerSender) {
        self.sendMsgSocketPartener("single-statistic-finished", {
          dbStats: dbStats,
        })
        if (!self.isTestInitiator) {
          if (!self.getTaskToRun()) self.allStatisticsDone()
        } else {
          if (self.countStatsForTask(dbStats.task_settings.id) >= 2) {
            self.runTest()
          }
        }
      } else {
        self.runTest()
      }
    },

    stopAllPeers(leaveRoom = true) {
      let self = this
      return new Promise((resolve, reject) => {
        let room = self.activeSpeedTest._key
        self.sender.getOrCreateConnection().then((lConn) => {
          self.receiver.getOrCreateConnection().then((rConn) => {
            self.sender.closeVideoCall(false)
            self.stream = null
            self.receiver.closeRemotesPreview().then(() => {
              if (leaveRoom) {
                lConn.emit("leave", room)
                rConn.emit("leave", room)
              }
              resolve()
            })
          })
        })
      })
    },

    getWorkTasks() {
      let self = this
      let tasks = []

      if (self.onInitExistWebrtcPartenerSender) {
        tasks.push({
          id: self.generateHashId("ptop"),
          type: "ptop",
          label: "Point-to-Point Stream",
          status: "awaiting",
          remote: {
            peerId: null,
          },
          local: {
            peerId: null,
          },
          stats: null,
        })

        tasks.push({
          id: self.generateHashId("ptop_turn"),
          type: "ptop_turn",
          label: "Point-to-Point Stream + TURNS",
          status: "awaiting",
          turnData: self.selectedTurnServers,
          remote: {
            peerId: null,
          },
          local: {
            peerId: null,
          },
          stats: null,
        })
      }
      _.forEach(self.selectedTurnServers, function (val) {
        tasks.push({
          id: self.generateHashId(val.data.name),
          type: "turn",
          label: val.data.name + " turn server",
          status: "awaiting",
          turnData: val,
          remote: {
            peerId: null,
          },
          local: {
            peerId: null,
          },
          stats: null,
        })
      })
      return tasks
    },

    generateHashId(inputString) {
      var shaObj = new jsSHA("SHA-256", "TEXT")
      shaObj.update(inputString)
      var hash = shaObj.getHash("HEX")
      return hash
    },

    sendMsgSocketPartener(type, params) {
      let self = this
      return new Promise((resolve, reject) => {
        if (self.onInitExistWebrtcPartenerSender) {
          if (self.partnerSignalId) {
            let msg_send = {
              from: "", //self.connection.id
              to: self.partnerSignalId,
              type: type,
              params: params,
            }
            if (self.sender) {
              self.sender.getOrCreateConnection().then(function (connLocal) {
                connLocal.emit("message", JSON.stringify(msg_send))
                resolve()
              })
            } else {
              resolve()
            }
          }
        } else {
          resolve()
        }
      })
    },

    runTest() {
      let self = this
      let taskToRun = self.getTaskToRun()
      if (taskToRun) {
        self.runTask(taskToRun)
      } else {
        self.allStatisticsDone()
      }
    },

    getTaskToRun() {
      let self = this
      return _.find(self.workerTasks, function (o) {
        return o.status === "awaiting"
      })
    },

    allStatisticsDone() {
      // all tasks finished
      let self = this
      self.parsedChartDataFinishedTest().then(() => {
        setTimeout(function () {
          self.parsedChartDataFinishedTest().then(() => {
            self.$store
              .dispatch(
                "speedTestSaveCalculatedStatisticsAction",
                self.workerTasks
              )
              .then(function () {
                self.$store
                  .dispatch("speedTestSaveIsFinishedAction", true)
                  .then(function () {
                    self.$store.dispatch(
                      "speedTestShowCompletedDialogAction",
                      true
                    )
                  })
              })
          })
        }, 1000)
      })
    },

    runTask(item) {
      let self = this
      setTimeout(function () {
        // Inform partner sender to start his stream and receive from this
        if (self.onInitExistWebrtcPartenerSender) {
          let resend = !self.isTestInitiator
          if (!resend) {
            self.startStats(item)
          }
          self.sendMsgSocketPartener("worker-run-task", {
            taskId: item.id,
            resend: resend,
          })
        } else {
          self.startStats(item)
        }
      }, 500)
    },

    startStats(item) {
      let self = this
      if (self.beginedTaskId !== item.id) {
        self.beginedTaskId = item.id
        return new Promise((resolve, reject) => {
          self.playerPlaying = false
          let prm1 = self.existFinishedTasks()
            ? self.stopAllPeers()
            : Promise.resolve()
          prm1.then(() => {
            let forceTurn = ["ptop", "ptop_turn"].includes(item.type)
              ? false
              : true
            let turn = item.type === "ptop" ? null : item.turnData //_.get(self.selectedTurnServers, '[0]')

            self.sender.props.turn = turn
            self.sender.props.forceTurn = forceTurn
            self.receiver.props.turn = turn
            self.receiver.props.forceTurn = forceTurn
            self.stopLocalVideo().then(() => {
              self
                .maybeCreateStream()
                .then(() => {
                  setTimeout(function () {
                    self.sender
                      .getOrCreateConnection()
                      .then(function (connLocal) {
                        self.receiver
                          .getOrCreateConnection()
                          .then(function (connRemote) {
                            if (self.existFinishedTasks()) {
                              let room = self.activeSpeedTest._key
                              connLocal.emit("join", room)
                              connRemote.emit("join", room)
                            }
                            setTimeout(function () {
                              self.sender
                                .getOrCreateConnection()
                                .then(function (connLocal) {
                                  let prm2
                                  if (self.onInitExistWebrtcPartenerSender) {
                                    prm2 = self.sendMsgSocketPartener(
                                      "worker-local-peers-initialized",
                                      {
                                        taskId: item.id,
                                        localPeerId: connLocal.id,
                                        wsId: self.clientWsId,
                                      }
                                    )
                                  } else {
                                    prm2 = self.startStatsAfterPeersInitialised(
                                      item,
                                      connLocal.id
                                    )
                                  }
                                  prm2
                                    .then(() => {
                                      self.$store
                                        .dispatch(
                                          "saveIsLocalStreamStarted",
                                          true
                                        )
                                        .then(() => {
                                          self.receiver.getPeerActiveCandidate()
                                          resolve()
                                        })
                                    })
                                    .catch((err) => {
                                      console.error("Error on startStats", err)
                                    })
                                })
                            }, 1000)
                          })
                      })
                  }, 2000)
                })
                .catch(() => {
                  // catch errors for maybeCreateStream
                  reject()
                })
            })
          })
        })
      }
    },

    startStatsAfterPeersInitialised(item, partnerLocalPeerId) {
      let self = this
      return new Promise((resolve) => {
        self.receiver.getOrCreateConnection().then(function (connRemote) {
          //self.sender.startFileStream(connRemote.id);
          self.receiver.makeOfferPreview(
            { id: partnerLocalPeerId },
            self.sender
          )
          setTimeout(function () {
            // self.getPartnerSenderId().then((partnerPeerId) => {
            self.$store
              .dispatch("speedTestSaveWebrtcPeersAction", {
                item: item,
                value: {
                  local: {
                    peerId: partnerLocalPeerId,
                  },
                  remote: {
                    peerId: connRemote.id,
                  },
                  status: "in-progress",
                },
              })
              .then(() => {
                resolve()
              })
            // });
          }, 1000)
        })
      })
    },

    existFinishedTasks() {
      return _.find(this.workerTasks, function (o) {
        return o.status == "finished"
      })
    },

    stopLocalVideo() {
      let self = this
      return new Promise((resolve) => {
        let localVideo = document.getElementById("local_video")
        if (self.localVideoStarted) {
          localVideo.pause()
          localVideo.currentTime = 0
          localVideo.load()
        }
        self.$store.dispatch("setLocalVideoStarted", false).then(() => {
          setTimeout(() => {
            resolve()
          }, 500)
        })
      })
    },

    maybeCreateStream() {
      let self = this
      return new Promise((resolve, reject) => {
        if (!self.localVideoStarted) {
          let localVideo = document.getElementById("local_video")
          self
            .fetchVideoFile(localVideo)
            .then(() => {
              let playPromise = localVideo.play()
              if (playPromise !== undefined) {
                playPromise
                  .then(function () {
                    self.$store
                      .dispatch("setLocalVideoStarted", true)
                      .then(() => {
                        if (self.stream) {
                          resolve()
                          return
                        }
                        if (localVideo.captureStream) {
                          self.stream = localVideo.captureStream()
                          // console.log('Captured stream from leftVideo with captureStream', self.stream);
                          self.sender.setLocalStreamFromFile(self.stream)
                        } else if (localVideo.mozCaptureStream) {
                          self.stream = localVideo.mozCaptureStream()
                          // console.log('Captured stream from leftVideo with mozCaptureStream()', self.stream);
                          self.sender.setLocalStreamFromFile(self.stream)
                        } else {
                          console.log("captureStream() not supported")
                        }
                        resolve()
                      })
                  })
                  .catch((err) => {
                    console.error("Video cannot be started", err)
                    reject()
                  })

                localVideo.onplaying = function () {
                  //self.$store.dispatch('setLocalVideoStarted', true)
                  self.playerPlaying = true
                }
                localVideo.onpause = function () {
                  //self.$store.dispatch('setLocalVideoStarted', false)
                  self.playerPlaying = false
                }
              } else {
                console.error(
                  "Video cannot be started: playPromise is undefined"
                )
                reject()
              }
            })
            .catch((err) => {
              console.error("Video playback failed", err)
              reject()
            })
        } else {
          console.error("Video cannot be started: already started before")
          reject()
        }
      })
    },

    fetchVideoFile() {
      let self = this
      return new Promise((resolve) => {
        let localVideo = document.getElementById("local_video")
        localVideo.setAttribute("src", self.videoSrc)
        localVideo.muted = true
        resolve()

        // @todo maybe later fetch mp4 file from local location, this ensure file loading (now from remote take too long time)
        // fetch('https://dl.dropboxusercontent.com/s/ekedg1cd8mskyop/arhttestvideo.mp4', { mode: 'no-cors' })
        //   .then(response => response.blob())
        //   .then(blob => {
        //     try {
        //       localVideo.srcObject = blob
        //     } catch (error) {
        //       localVideo.src = URL.createObjectURL(blob)
        //     }
        //     resolve()
        //   })
      })
    },

    // get other web partner (client) peerID
    getPartnerSenderId() {
      let self = this
      return new Promise((resolve, reject) => {
        if (self.sender) {
          self.sender
            .getOrCreateConnection()
            .then(function (cn) {
              let parnerPeerId = null
              if (self.onInitExistWebrtcPartenerSender) {
                let pSenders = _.filter(self.roomMembersSignal.senders, (v) =>
                  v.nickName.includes(self.partnerWsId)
                )
                if (pSenders.length > 0) {
                  parnerPeerId = pSenders[0].id
                }
              } else {
                parnerPeerId = cn.id
              }
              resolve(parnerPeerId)
            })
            .catch(() => {
              reject()
            })
        } else {
          reject()
        }
      })
    },

    countStatsForTask(taskId) {
      let statsT = _.filter(
        this.activeSpeedTest.stats.raw,
        (o) => o.task_settings.id == taskId
      )
      return statsT.length
    },

    /**
     * Restart test
     *
     * @param    sendMsgPartner    boolean       If send to partner command to also stop the test
     * @param    showStartDialog    boolean       After stop test, show or not dialog with buttons to start test
     */
    restartTest() {
      let self = this
      self.saveRestart = true
      self.beginedTaskId = null
      self.stopTestOnRestart().then(() => {
        self.markIsTestSaving(true).then(() => {
          self.saveStatsToDB(false).then(() => {
            self.startWaitingPartner().then(() => {
              self.$store.dispatch("speedTestResetStatsAction").then(() => {
                self.saveRestart = false
                self.runTestFromDialog()
                self.markIsTestSaving(false)
              })
            })
          })
        })
      })
    },

    /**
     * Stop test and clear tasks list
     *
     * @param    sendMsgPartner    boolean       If send to partner command to also stop the test
     */
    stopTest(sendMsgPartner = false) {
      let self = this
      self.beginedTaskId = null
      self.stopAllPeers(false).then(() => {
        self.$store.dispatch("speedTestStopAllTasksAction")
        if (sendMsgPartner && self.onInitExistWebrtcPartenerSender) {
          self.$store.dispatch("speedTestPartnerDisconnectedAction", true)
          self.sendMsgSocketPartener("stop-test").then(() => {
            // do something
          })
        }
      })
      self.$store.dispatch("speedTestSaveWorkerTasksAction", []).then(() => {
        self.$store.dispatch("saveInitializeSpeedTestAction", {
          testInProgress: false,
          //isTestInitiator: false
        })
      })
      self.playerPlaying = false
      self.sender.getAspxStreamsList()
    },

    stopTestOnRestart(sendMsgPartner = false) {
      let self = this
      return new Promise((resolve, reject) => {
        self.stopAllPeers(false).then(() => {
          self.$store.dispatch("speedTestStopAllTasksAction")
          if (sendMsgPartner && self.onInitExistWebrtcPartenerSender) {
            self.sendMsgSocketPartener("stop-restart-test").then(() => {
              // do something
            })
          }
          self.$store
            .dispatch("speedTestSaveWorkerTasksAction", [])
            .then(() => {
              self.playerPlaying = false
              self.sender.getAspxStreamsList()
              resolve()
            })
        })
      })
    },

    runTestFromDialog() {
      let self = this
      self.sender.getOrCreateConnection().then((lConn) => {
        self.receiver.getOrCreateConnection().then(function (rConn) {
          self
            .getPartnerSenderId()
            .then((partnerPeerId) => {
              self.partnerSignalId = partnerPeerId
              self.onInitExistWebrtcPartenerSender =
                self.existWebrtcPartenerSender
              self.$root.$emit("initializeTest")
            })
            .catch(() => {
              // Error on getPartnerSenderId
            })
        })
      })
    },

    startWaitingPartner() {
      let self = this
      return new Promise((resolve, reject) => {
        self.stopAllPeers().then(() => {
          let p1 = self.$store.dispatch("speedTestSaveWorkerTasksAction", [])
          let p2 = self.$store.dispatch(
            "speedTestPartnerDisconnectedAction",
            false
          )
          let p3 = self.$store.dispatch("saveInitializeSpeedTestAction", {
            testInProgress: false,
          })
          let p4 = self.$store.dispatch("speedTestSaveIsFinishedAction", false)
          let p5 = self.$store.dispatch(
            "speedTestShowCompletedDialogAction",
            false
          )

          Promise.all([p1, p2, p3, p4, p5]).then(() => {
            self.sender.getOrCreateConnection().then(function (connLocal) {
              self.receiver.getOrCreateConnection().then(function (connRemote) {
                let room = self.activeSpeedTest._key
                connLocal.emit("join", room)
                connRemote.emit("join", room)
                resolve()
              })
            })
          })
        })

        // self.$store.dispatch('speedTestSaveWorkerTasksAction', []).then(() => {
        // self.$store.dispatch('speedTestPartnerDisconnectedAction', false);
        // self.$store.dispatch('saveInitializeSpeedTestAction', {
        //     testInProgress: false,
        // });
        // self.sender.getOrCreateConnection().then(function (connLocal) {
        //     self.receiver.getOrCreateConnection().then(function (connRemote) {
        //         let room = self.activeSpeedTest._key;
        //         connLocal.emit('join', room);
        //         connRemote.emit('join', room);
        //         self.$store.dispatch('speedTestSaveIsFinishedAction', false).then(function () {
        //             self.$store.dispatch('speedTestShowCompletedDialogAction', false).then(() => {
        //                 resolve();
        //             });
        //         });
        //     });
        // });
      })
      //});
    },

    saveUnfinishedStats() {
      let self = this
      self.markIsTestSaving(true).then(() => {
        self.saveStatsToDB().then(() => {
          self.closeDialogLocal()
          self.$store.dispatch("isSavingSpeedTest", false)
        })
      })
    },

    saveCompletedStats(savingAfterTimeout = false) {
      let self = this
      return new Promise((resolve, reject) => {
        if (
          !savingAfterTimeout ||
          (savingAfterTimeout && self.currentUserCanSaveData)
        ) {
          self.markIsTestSaving(true).then(() => {
            self.saveStatsToDB().then(() => {
              self.sendMsgSocketPartener("statistic-saved")
              self.closeDialog()
              self.$store.dispatch("isSavingSpeedTest", false)
              resolve()
            })
          })
        } else {
          resolve()
        }
      })
    },

    saveStatsToDB(markIsFinished = true) {
      let self = this
      return new Promise((resolve, reject) => {
        self.parsedChartDataFinishedTest().then(() => {
          setTimeout(function () {
            self.parsedChartDataFinishedTest().then(() => {
              self.$store
                .dispatch(
                  "speedTestSaveCalculatedStatisticsAction",
                  self.workerTasks
                )
                .then(function () {
                  let prmsFinished = Promise.resolve()
                  if (markIsFinished) {
                    prmsFinished = self.$store.dispatch(
                      "speedTestSaveIsFinishedAction",
                      true
                    )
                  }
                  prmsFinished.then(function () {
                    self
                      .postResultData()
                      .then(() => {
                        if (markIsFinished) {
                          self.$store.dispatch(
                            "saveInitializeSpeedTestAction",
                            {
                              testInProgress: false,
                            }
                          )
                          self.$store.dispatch(
                            "speedTestShowCompletedDialogAction",
                            false
                          )
                        }
                        resolve()
                      })
                      .catch((err) => {
                        console.log("Error on save statistic to db", err)
                        reject(err)
                      })
                  })
                })
            })
          }, 1000)
        })
      })
    },

    // generate pdf file without mark test as finished
    viewResultsAsPdf() {
      let self = this
      self.$store.dispatch("speedTestLoaderPdfAction", true)
      self.markIsTestSaving(true).then(() => {
        self.saveStatsToDB(true).then(() => {
          self.$store.dispatch("isSavingSpeedTest", false).then(() => {
            setTimeout(() => {
              self.savePdf()
            }, 10)
          })
        })
      })
    },

    savePdf() {
      let self = this
      if (self.editedIndex !== -1) {
        let getData = {
          slug: self.editedIndex,
        }
        self.$store.dispatch("speedTestGetPdf", getData).then(() => {
          self.$store.dispatch("speedTestLoaderPdfAction", false)
        })
      }
    },

    bandwithChanged(val) {
      this.sender.props.bandwidth = val
      this.receiver.props.bandwidth = val
    },

    checkFinishStatisticZero() {
      if (this.statisticZero.me && this.statisticZero.partner) {
        this.$root.$emit("statistic-zero-finish", this.inProgressWorkerTask)
      }
    },

    handlePeerConnBroken(peer) {
      this.$root.$emit("peer-conn-broken", this.inProgressWorkerTask)
    },

    markIsTestSaving(status = true) {
      let self = this
      return new Promise((resolve) => {
        self.sendMsgSocketPartener("is-saving-speed-test", status)
        self.$store.dispatch("isSavingSpeedTest", status).then(() => {
          resolve()
        })
      })
    },
  },

  computed: {
    ...mapGetters({
      testInProgress: "getTestInProgres",
      roomMembersSignal: "getRoomMembersWsConn",
      isTestInitiator: "getIsTestInitiator",
      isPreviewRemoteStreamStarted: "getIsPreviewRemoteStreamStarted",
      activeSpeedTest: "getActiveSpeedTest",
      selectedTurnServers: "getSelectedTurnsByUrls",
      workerTasks: "getWorkerTasks",
      inProgressWorkerTask: "inProgressWorkerTask",
      existWebrtcPartenerSender: "existWebrtcPartenerSender",
      roomCliens: "getSpeedTestRoomClients",
      showRunTestDialog: "showRunTestDialog",
      showPartnerDisconnectedDialog: "showPartnerDisconnectedDialog",
      showTestCompletedDialog: "showTestCompletedDialog",
      isCurrentClientAllowed: "isCurrentClientAllowed",
      clientWsId: "getSpeedTestClientId",
      partnerWsId: "getSpeedTestWsPartnerId",
      peers: "getWebrtcPeers",
      roomMembersSignalSenders: "signalRoomMembersSenders",
      currentUserCanSaveData: "currentUserCanSaveData",
      localVideoStarted: "localVideoStarted",
      speedTestSyncData: "speedTestSyncData",
      stSyncDataClients: "stSyncDataClients",
      isPartnerOut: "isPartnerOut",
      Pallette: "get_Pallette",
    }),
    ...mapState({
      errorText: (state) => state.moduleEngineSpeedTest.errorText,
    }),
    getKbBandWidth() {
      let bndwth = "unlimited"
      if (this.activeSpeedTest.bandwidth) {
        bndwth = this.activeSpeedTest.bandwidth * 100
        bndwth = bndwth.toString()
      }
      return bndwth
    },
    localPeerNickname() {
      return "speed-test-" + this.uniqueId
    },
    remotePeerNickname() {
      return "speed-test-" + this.uniqueId
    },
  },
}
</script>

<style lang="scss">
.dialog-has-second-partner .v-card__actions,
.dialog-not-partner .v-card__actions,
.dialog-speed-room-busy .v-card__actions {
  padding: 16px;
}

#received_video_container video {
  max-width: 100%;
  width: 100%;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/components/speed-test.scss";
</style>
