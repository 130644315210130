import _ from "lodash"
import { mapGetters } from "vuex"
import ReconnectingWebSocket from "reconnecting-websocket"

export default {
  data() {
    return {
      pingClients: null,
    }
  },
  beforeDestroy() {
    this.stopPingClients()
    this.$store.dispatch("speedTestSaveRoomCliensAction", [])
  },
  mounted() {
    let self = this
    this.$store.dispatch("speedTestSaveRoomCliensAction", [])
    setTimeout(function () {
      self.$store.dispatch("saveLoaderAction", false)
      self.$store
        .dispatch("speedTestSaveClientIdAction", self.uniqueId)
        .then(() => {
          self.doPingClients()
        })
    }, 2000)
  },
  watch: {
    websocket_common_conn: {
      immediate: true,
      handler(val, oldVal) {
        let self = this
        if (val && val instanceof ReconnectingWebSocket && val !== oldVal) {
          self.websocket_common_conn.addEventListener("message", (msg) => {
            if (Object.keys(msg).length > 0) {
              let data = JSON.parse(msg.data)
              if (_.get(data, "path", "") == "1.0.speed.test.room.users") {
                let activeClients = _.get(data, "data.active_clients", [])
                self.$store.dispatch(
                  "speedTestSaveRoomCliensAction",
                  activeClients
                )
              }
            }
          })
        }
      },
    },
  },
  methods: {
    stopPingClients() {
      if (this.pingClients) {
        clearInterval(this.pingClients)
        this.pingClients = null
      }
    },
    doPingClients() {
      let self = this
      self.pingWs()
      self.pingClients = setInterval(() => {
        self.pingWs()
      }, 4000)
    },

    pingWs() {
      let self = this
      if (self.websocket_common_conn) {
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.speed.test.room.users",
            args: {
              room_key: self.editedIndex,
              client_key: self.clientWsId,
            },
          })
        )
      }
    },
  },
  computed: {
    ...mapGetters({
      websocket_common_conn: "getWebsocketCommonConnection",
      clientWsId: "getSpeedTestClientId",
    }),
  },
}
