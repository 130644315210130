<template>
  <div data-testid="containers-status-dialogs">
    <v-dialog
      v-if="!showLoader"
      v-model="dClientAllowed"
      dark
      max-width="600"
      persistent
      content-class="dialog-speed-room-busy"
      key="key-dialog-speed-room-busy"
      :transition="false"
    >
      <v-card dark data-testid="container-test-busy-wait">
        <v-card-text>
          <v-flex class="py-4" sm12 xs12>
            <div class="title pb-3" data-testid="title-test-busy-wait">
              Test is busy, please wait...
            </div>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog()"
            color="red darken-1"
            text
            data-testid="btn-close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="clientNotAllowDisconnect"
      dark
      max-width="600"
      persistent
      content-class="dialog-speed-room-busy"
      key="key-test-busy-uncomplete"
      :transition="false"
    >
      <v-card dark data-testid="container-test-busy-another-client">
        <v-card-text>
          <v-flex class="py-4" sm12 xs12>
            <div
              class="title pb-3"
              data-testid="title-test-busy-another-client"
            >
              Test is busy<br />
              another client must save or close uncomplete test...
            </div>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog()"
            color="red darken-1"
            text
            data-testid="btn-close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showRunTestDialog"
      dark
      max-width="600"
      persistent
      content-class="dialog-has-second-partner"
      key="key-dialog-has-second-partner"
      :transition="false"
    >
      <v-card dark data-testid="container-run-two-clients">
        <v-card-text>
          <v-flex class="py-4" sm12 xs12>
            <div class="title pb-3" data-testid="title-run-two-clients">
              Your partner is online.
            </div>
            <div data-testid="partner-ip">Partner’s IP: {{ partnerInfo }}</div>
          </v-flex>
          <v-layout class="py-4" row wrap>
            <v-flex sm12 xs12 data-testid="container-chat">
              <chat :room="activeSpeedTest._key" />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog()"
            color="red darken-1"
            text
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            @click="runTwoPointsTest()"
            color="indigo"
            dark
            text
            :disabled="roomMembersSignalSenders.length < 2"
            :loading="roomMembersSignalSenders.length < 2"
            data-testid="btn-start-test"
          >
            Start Test
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDialogTestOnlyTurns"
      dark
      max-width="600"
      persistent
      content-class="dialog-not-partner"
      key="dialog-st-test-turns"
      :transition="false"
      data-testid="container-run-one-client"
    >
      <v-card dark>
        <v-card-text>
          <v-flex class="py-4" sm12 xs12>
            <div class="title pb-3" data-testid="title-run-one-client">
              Your partner is not logged in yet.
            </div>
            <div>
              You can wait or do a test using our servers without partner.
            </div>
            <!--                            <a @click="goToEditPage()">our servers</a> -->
            <div class="subheading pt-3">Waiting for partner...</div>
          </v-flex>
          <v-layout class="py-4" row wrap>
            <v-flex sm6 xs6>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :error-messages="errors.collect('form-speed-test.duration')"
                    outlined
                    data-vv-name="duration"
                    label="Test time"
                    max="59"
                    min="1"
                    suffix="min"
                    type="number"
                    v-model="activeDuration"
                    v-validate="'required|min:1|max:59'"
                    data-testid="duration"
                  />
                </v-col>
              </v-row>
            </v-flex>

            <v-flex sm6 xs6>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :error-messages="
                      errors.collect('form-speed-test.bandwidth')
                    "
                    outlined
                    class="pl-2"
                    data-vv-name="bandwidth"
                    label="Bandwidth"
                    min="0"
                    suffix="Mbps"
                    type="number"
                    v-model="activeBandwidth"
                    v-validate="'required'"
                    data-testid="bandwidth"
                  />
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog()"
            color="red darken-1"
            text
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            @click="runSinglePointServersTest()"
            color="indigo"
            dark
            text
            :disabled="!allowedButtonTestOnlyTurns"
            :loading="!allowedButtonTestOnlyTurns"
            data-testid="btn-start-test"
          >
            Test with servers
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showPartnerDisconnectedDialog"
      dark
      max-width="600"
      persistent
      content-class="dialog-partner-disconnected"
      key="key-dialog-partner-disconnected"
      :transition="false"
    >
      <v-card dark data-testid="container-partner-disconnected">
        <v-card-text>
          <v-flex class="py-4" sm12 xs12>
            <div class="title pb-3" data-testid="title-partner-disconnected">
              Your partner disconnected.
            </div>
            <div>
              Would you like to save incomplete results or wait for the partner
              and start over?
            </div>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog()"
            color="red darken-1"
            text
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            @click="$emit('saveUnfinishedStats')"
            color="success"
            dark
            text
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      dark
      max-width="650"
      persistent
      v-model="showTestCompletedDialog"
      content-class="dialog-test-completed"
      key="key-dialog-test-completed"
      :transition="false"
      data-testid="container-test-complete"
    >
      <v-card dark>
        <v-card-text>
          <v-flex class="py-4" sm12 xs12>
            <div class="title pb-3" data-testid="title-test-complete">
              Test is complete.
            </div>
            <!-- Because all tests should get in the history -->
            <!-- <div class="mb-2">
              <v-btn class="ml-0" @click="clickViewResultsAsPdf()" color="default" dark :loading="loaderPdf"
                     :disabled="loaderPdf">View Results as PDF
              </v-btn>
            </div> -->
            <div>
              <p>
                If you think these results are representative of the network
                condition please press [Save & Close]
              </p>
              <p>
                If you would like to make some changes and re-test please chose
                [Close Without Saving] or
              </p>
              <p>chose [Restart] if you are ready to test again.</p>
            </div>
          </v-flex>
        </v-card-text>
        <v-card-actions v-if="$vuetify.breakpoint.smAndUp">
          <v-spacer></v-spacer>
          <!-- $vuetify.breakpoint.smAndUp -->
          <v-btn
            @click="$emit('closeDialogLocal')"
            color="red darken-1"
            text
            :disabled="isSavingSpeedTest"
            data-testid="btn-close-no-saving"
          >
            Close Without Saving
          </v-btn>
          <v-btn
            @click="restartTest()"
            color="indigo"
            dark
            text
            :disabled="isSavingSpeedTest"
            data-testid="btn-restart"
          >
            Restart
          </v-btn>
          <v-btn
            v-if="timeoutObj || isSavingSpeedTest"
            @click="$emit('saveCompletedStats')"
            color="success"
            dark
            text
            :loading="isSavingSpeedTest"
            :disabled="isSavingSpeedTest"
            data-testid="btn-finish-save"
          >
            Finish test and Save<span>… in {{ timeLeft }} sec</span>
            <template v-slot:loader>
              <span>Saving result...</span>
            </template>
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndUp">
          <v-row
            v-if="!$vuetify.breakpoint.smAndUp"
            align="center"
            justify="space-around"
          >
            <VerticalButton
              @click.native="
                !isSavingSpeedTest ? $emit('closeDialogLocal') : false
              "
              icon="mdi-close-box-outline"
              text="Close Without Saving"
              color="red darken-1"
              :disabled="isSavingSpeedTest"
              data-testid="btn-close-no-saving-sm"
            />
            <VerticalButton
              @click.native="!isSavingSpeedTest ? restartTest() : false"
              :icon="saveRestart ? '' : 'mdi-backup-restore'"
              :text="saveRestart ? 'Saving result...' : 'Restart'"
              color="indigo"
              :disabled="isSavingSpeedTest"
              data-testid="btn-restart-sm"
            />
            <VerticalButton
              @click.native="
                !isSavingSpeedTest ? $emit('saveCompletedStats') : false
              "
              v-if="timeoutObj"
              icon="mdi-timer-sand"
              :text="'Finish test and Save … in ' + timeLeft + ' sec'"
              color="success"
              :disabled="isSavingSpeedTest"
              data-testid="btn-finish-save-sm"
            />
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Chat from "@/components/chat/Chat"
import STCommonMixin from "@/mixins/speed-test/common"
import VerticalButton from "@/components/buttons/VerticalButton"

export default {
  name: "speed-test-status-dialogs",

  data() {
    return {
      dClientAllowed: false,
      timeLeft: 59,
      timeoutObj: null,
      counter: 59,
    }
  },

  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
    saveRestart: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Chat,
    VerticalButton,
  },

  mixins: [STCommonMixin],

  watch: {
    showDialogClientNotAllowed: {
      handler(val) {
        let self = this
        setTimeout(() => {
          self.dClientAllowed = val
          self.$store.dispatch("saveLoaderAction", false)
        }, 100)
      },
      deep: true,
    },

    showTestCompletedDialog: function (val) {
      if (val) {
        this.startTimer()
      }
    },

    // @todo Show loader allways when no other dialogs
    // showLoaderDialog: {
    //     handler(val, oldVal) {
    //         let self = this;
    //         console.log('', val, oldVal);
    //         if (val) {
    //             self.$store.dispatch('saveLoaderAction', true);
    //         } else {
    //             self.$store.dispatch('saveLoaderAction', false);
    //         }
    //     },
    //     deep: true
    // },
  },

  methods: {
    runSinglePointServersTest() {
      let self = this
      let newSyncData = {
        ...self.speedTestSyncData,
        ...{ type: "single-point" },
      }
      self.$store
        .dispatch("speedTestEditAction", {
          slug: self.activeSpeedTest?._key,
          data: {
            duration: parseInt(self.activeSpeedTest.duration),
            bandwidth: parseInt(self.activeSpeedTest.bandwidth),
            sync_data: newSyncData,
          },
        })
        .then(({ data }) => {
          self.$root.$emit("initializeTest")
        })
    },
    runTwoPointsTest() {
      let self = this
      let newSyncData = {
        ...self.speedTestSyncData,
        ...{ type: "two-points", clients: [self.clientWsId] },
      }
      self.$store
        .dispatch("speedTestEditAction", {
          slug: self.activeSpeedTest?._key,
          data: {
            duration: parseInt(self.activeSpeedTest.duration),
            bandwidth: parseInt(self.activeSpeedTest.bandwidth),
            sync_data: newSyncData,
          },
        })
        .then(({ data }) => {
          self.getItemData().then(() => {
            self.$emit("runTestFromDialog")
            self.$store.dispatch("saveLoaderAction", true)
            setTimeout(() => {
              self.$store.dispatch("saveLoaderAction", false)
            }, 3000)
          })
        })
    },

    startTimer() {
      this.stopTimer()
      this.timeoutObj = setInterval(this.countDown, 1000)
    },

    stopTimer() {
      clearInterval(this.timeoutObj)
      this.timeoutObj = null
      this.timeLeft = this.counter
    },

    countDown() {
      let self = this
      if (this.timeLeft <= 1) {
        self.$store.dispatch("isSavingSpeedTest", true).then(() => {
          self.stopTimer()
          self.$emit("saveCompletedStats", true)
        })
      } else {
        this.timeLeft--
      }
    },

    restartTest() {
      this.stopTimer()
      this.$emit("restartTest")
    },

    clickViewResultsAsPdf() {
      this.$emit("viewResultsAsPdf")
    },

    saveParams() {
      let self = this
      let formData = {
        slug: self.editedIndex,
        data: {
          duration: parseInt(self.activeSpeedTest.duration),
          bandwidth: parseInt(self.activeSpeedTest.bandwidth),
        },
      }
      self.$store.dispatch("speedTestEditAction", formData).catch((error) => {
        console.error("error on saveParams", error)
      })
    },
  },

  computed: {
    ...mapGetters({
      showLoader: "getShowLoader",
      showRunTestDialog: "showRunTestDialog",
      showDialogTestOnlyTurns: "showDialogTestOnlyTurns",
      showPartnerDisconnectedDialog: "showPartnerDisconnectedDialog",
      showTestCompletedDialog: "showTestCompletedDialog",
      activeSpeedTest: "getActiveSpeedTest",
      testInProgress: "getTestInProgres",
      partnerInfo: "getSpeedTestPartnerInfo",
      roomMembersSignalSenders: "signalRoomMembersSenders",
      loaderPdf: "getLoaderPdf",
      showDialogClientNotAllowed: "showDialogClientNotAllowed",
      speedTestSyncData: "speedTestSyncData",
      clientWsId: "getSpeedTestClientId",
      stSyncDataClients: "stSyncDataClients",
      isPartnerOut: "isPartnerOut",
      currentClientNotInHistory: "currentClientNotInHistory",
      isCurrentClientAllowed: "isCurrentClientAllowed",
      clientNotAllowDisconnect: "clientNotAllowDisconnect",
    }),

    allowedButtonTestOnlyTurns() {
      return !this.testInProgress && this.roomMembersSignalSenders.length < 2
    },

    activeDuration: {
      get: function () {
        return this.activeSpeedTest.duration
      },
      set: function (newValue) {
        this.$store.dispatch("speedTestSaveDuration", newValue).then(() => {
          this.saveParams()
        })
      },
    },

    activeBandwidth: {
      get: function () {
        return this.activeSpeedTest.bandwidth
      },
      set: function (newValue) {
        this.$emit("bandwithChanged", newValue)
        this.$store.dispatch("speedTestSaveBandwidth", newValue).then(() => {
          this.saveParams()
        })
      },
    },
    isSavingSpeedTest() {
      return this.$store.state.moduleEngineSpeedTest.isSavingSpeedTest
    },

    // areShowedSomeDialogStatus(){
    //   // check is some of dialog
    //   return this.dClientAllowed || this.showRunTestDialog || this.showDialogTestOnlyTurns ||
    //     this.showPartnerDisconnectedDialog || this.showTestCompletedDialog;
    // }

    // showLoaderDialog(){
    //     return !this.testInProgress && !this.dClientAllowed&& !this.showRunTestDialog && !this.showDialogTestOnlyTurns && !this.showPartnerDisconnectedDialog && !this.showTestCompletedDialog;
    // }
  },
}
</script>
